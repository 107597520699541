<template>
  <div class="link-home">
    <div class="header">
      <van-nav-bar
        :title="this.$route.params.rname"
        left-arrow
        @click-left="onClickLeft"
      ></van-nav-bar>
      <van-icon name="cross" class="close" @click="closeTab" />
    </div>

    <div id="iframe-container">
      <iframe
        :src="this.$route.params.url"
        scrolling="auto"
        frameborder="0"
        id="iframe"
      ></iframe>
    </div>
  </div>
</template>

<script>
import NProgress from 'nprogress'

export default {
  mounted () {
    const iframe = document.getElementById('iframe')
    NProgress.start()

    iframe.onload = function () {
      NProgress.done()
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
      NProgress.done()
    },

    closeTab () {
      this.$router.replace('/pointinfo')
      NProgress.done()
    }
  }
}
</script>

<!-- css样式省略 -->
